import {useLayoutStore} from "../store/layout";

type AppModule = {
    name: string;
    icon: string;
    search?: Searchable;
    color?: string;
}

type Searchable = {
    detail: string; // detail route name, e.g. "users-id-userBaseData"
    listing?: string; // listing route name, defaults to just <searchable>, e.g. "users"
    route?: string; // backend index route, defaults to table module route or spa.<searchable>.index, if not provided
    nameProp?: string; // name property to display in search results, defaults to "name"
    idProp?: string; // id property to display in search results, defaults to "id"
}

type AppModules = Record<string, AppModule>

export const $getCurrentModule = () => {
    return appModules[useLayoutStore().currentModule] ?? null
}

export const $getModuleIcon = (module?: string) => {
    if (!module) return $getCurrentModule()?.icon
    return appModules[module].icon ?? null
}

export const $getModuleColor = (module?: string) => {
    if (!module) return $getCurrentModule()?.color
    return appModules[module].color ?? null
}

export const appModules: AppModules = {
    dashboard: {
        name: 'dashboard',
        icon: 'heroicons:home'
    },
    users: {
        name: 'users',
        icon: 'heroicons:users',
        color: 'indigo',
        search: {
            detail: 'users-id-userBaseData',
            nameProp: 'fullname'
        },
    },
    // organisations: {
    //     name: 'organisations',
    //     icon: 'heroicons:user',
    //     color: 'teal',
    //     search: {
    //         detail: 'organisations-id-organisationBaseData'
    //     },
    // },
    configurator: {
        name: 'configurator',
        icon: 'heroicons:adjustments-horizontal-20-solid',
    },
    orders: {
        name: 'orders',
        icon: 'heroicons:shopping-bag',
        color: 'amber',
    },
    settings: {
        name: 'settings',
        icon: 'heroicons:cog-6-tooth',
    },
}
