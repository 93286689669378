import {defineStore} from 'pinia'
import {useAuthStore} from './auth'
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import {useEventBus} from '@vueuse/core'

export const useWebsocketStore = defineStore('websockets', {
    state: () => ({
        channel: null,
        members: {},
    }),

    getters: {
        /*userActive: (state) => {
            return (userId) => {


            }
        },*/
    },

    actions: {
        async init(mode = 'default') {
            const config = useRuntimeConfig()
            window.Pusher = Pusher
            window.Echo = new Echo({
                broadcaster: 'pusher',
                key: config.public.websockets.key,
                host: window.location.hostname,
                httpHost: config.public.websockets.host,
                wsHost: config.public.websockets.host,
                cluster: config.public.websockets.cluster,
                forceTLS: config.public.websockets.forceTLS,
                disableStats: config.public.websockets.disableStats,
                enabledTransports: config.public.websockets.forceWSS ? ['wss'] : ['wss', 'ws'],
                authorizer: (channel: any, options: any) => {
                    return {
                        authorize: async (socketId: any, callback: any) => {

                            await $lara.post("/app/spa/broadcasting/auth", {
                                body: {
                                    socket_id: socketId,
                                    channel_name: channel.name
                                }
                            }).then((response) => {
                                callback(false, response);
                            }).catch(() => {
                                callback(true);
                            })
                        }
                    }
                }
            })

            if (mode === 'default') {
                this.joinUserPrivate()
            }

            window.Echo.connector.pusher.connection.bind('state_change', function (states: any) {
                if (states.current === 'disconnected') {
                    window.Echo.connector.pusher.connect();
                }
            });
        },

        joinUserPrivate() {
            window.Echo.private('App.Models.User.' + useAuthStore().userData.id)
                .notification((notification: any) => {
                    useEventBus('notifications').emit(notification)
                })
                .error((error: Error) => {
                    useNotification().error(useI18n().t('error'), error.message)
                })
        },
    }
})