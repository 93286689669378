<template>
    <div>
        <!-- Profile dropdown -->
        <Menu
            v-if="authStore.userData"
            v-slot="{ open }"
            as="div"
            class="relative">
            <MenuButton class="-m-1.5 flex p-1.5">
                <span class="sr-only">Open user menu</span>
                <div class="flex items-center relative">
                    <img
                        v-if="authStore.userData.image_url"
                        class="h-8 w-8 object-cover rounded-full bg-gray-50"
                        :src="authStore.userData.image_url"
                        alt="">
                    <Icon
                        v-else
                        name="heroicons:user-circle"
                        class="h-8 w-8 text-gray-400 dark:text-gray-300" />
                    <span
                        v-if="versionMismatch"
                        class="w-2 h-2 bg-danger-500 block absolute bottom-0 right-0 rounded-full" />
                </div>
                <span
                    class="hidden lg:flex lg:items-center">
                    <div>
                        <span
                            class="block ml-4 text-sm font-semibold leading-none text-gray-900 dark:text-gray-100"
                            aria-hidden="true">{{ authStore.userData.fullname }}</span>
                        <span class="leading-none text-sm text-gray-400 dark:text-gray-200">
                            {{ authStore.userData.organisation_name }}
                        </span>
                    </div>
                    <Icon
                        name="heroicons:chevron-down"
                        class="ml-2 h-5 w-5 text-gray-400 transition-transform duration-150 ease-in-out"
                        :class="{'rotate-180': open}"
                        aria-hidden="true" />
                </span>
            </MenuButton>
            <Transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                    class="absolute right-0 z-10 mt-2.5 origin-top-right rounded-md bg-white dark:bg-gray-700 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <NuxtLink :to="useLocalePath()('/service/users/' + authStore.userData.id + '/userBaseData')">
                        <BaseMenuItem
                            icon="heroicons:user"
                            icon-class="ml-6">
                            {{ $t('menu.myProfile') }}
                        </BaseMenuItem>
                    </NuxtLink>
                    <BaseMenuItem
                        icon="heroicons:information-circle"
                        icon-class="ml-6"
                        @click="showUserInfoModal = true">
                        <template #icon>
                            <span class="relative ml-6">
                                <Icon
                                    name="heroicons:information-circle"
                                    class="w-5 h-5" />

                                <span
                                    v-if="versionMismatch"
                                    class="w-2 h-2 bg-danger-500 block absolute bottom-0 right-0 rounded-full" />
                            </span>
                        </template>
                        {{ $t('menu.information') }}
                    </BaseMenuItem>
                    <!--                    <BaseMenuItem-->
                    <!--                        class="cursor-default"-->
                    <!--                        @click="$event => $event.preventDefault()">-->
                    <!--                        <template #icon>-->
                    <!--                            <BaseSwitch-->
                    <!--                                v-model="isDark"-->
                    <!--                                name="dark-mode"-->
                    <!--                                :icons="['heroicons:sun', 'heroicons:moon']" />-->
                    <!--                        </template>-->
                    <!--                        Darkmode-->
                    <!--                    </BaseMenuItem>-->
                    <!--                    <BaseMenuItem-->
                    <!--                        class="cursor-default"-->
                    <!--                        @click="$event => $event.preventDefault()">-->
                    <!--                        <template #icon>-->
                    <!--                            <BaseSwitch-->
                    <!--                                v-model="languageToggle"-->
                    <!--                                name="locale"-->
                    <!--                                :icons="['circle-flags:de', 'circle-flags:en']" />-->
                    <!--                        </template>-->
                    <!--                        {{ $t(`locales.${lang}`) }}-->
                    <!--                    </BaseMenuItem>-->
                    <BaseMenuItem
                        icon="heroicons:arrow-left-on-rectangle"
                        icon-class="ml-6"
                        @click="logout">
                        {{ $t('menu.logout') }}
                    </BaseMenuItem>
                </MenuItems>
            </Transition>
        </Menu>
        <UserInfoModal v-model="showUserInfoModal" />
    </div>
</template>

<script setup>
import {useAuthStore} from "@/store/auth";
import {useLanguage} from "@/composables/useLanguage";

const loading = ref(false)
const showUserInfoModal = ref(false)
const authStore = useAuthStore()
const {toggle: languageToggle, lang} = useLanguage()

const {versionIsUpToDate, versionMismatch} = useAppVersion()

const logout = async () => {
    loading.value = true
    await authStore.logout()
    loading.value = false
}

// const isDark = useDark({
//     disableTransition: false
// })
</script>

<style scoped>

</style>