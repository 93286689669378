type TypeDefinition = {
    icon: string;
    color?: string
}

type TypeDefinitions = Record<string, TypeDefinition>

export const requestLogTypes: TypeDefinitions = {
    type_default_action: {
        icon: 'heroicons:information-circle',
        color: 'gray'
    },
    type_user_deleted: {
        icon: 'heroicons:trash',
        color: 'danger'
    },
    type_user_not_deletable: {
        icon: 'heroicons:exclamation-triangle',
        color: 'warning'
    },
}
