import releaseNotesDe from './releaseNotes/releaseNotesDe.json'
import releaseNotesEn from './releaseNotes/releaseNotesEn.json'

type FeatureNote = {
    description: string;    // description of the feature
    module?: string;        // legacy amosWEB param, maybe remove
    html?: boolean;         // parse as html
    annotation?: string;    // short annotation/footnote for the feature
}

type ReleaseNote = {
    release: string;        // release version
    type?: string;          // release type (feature/bugfix)
    date?: string;          // release date
    tags?: string[];        // tags for the release (default 3-4)
    notes?: FeatureNote[];   // release notes
    fixes?: FeatureNote[];  // fixes
}

type ReleaseNotes = ReleaseNote[]

export const release_notes_de: ReleaseNotes = releaseNotesDe
export const release_notes_en: ReleaseNotes = releaseNotesEn

